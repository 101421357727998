
// 'base' partial
//
// To make it easier to use all variables and mixins in any SCSS file in this
// theme, each .scss file has a @import 'base' declaration. And this base.scss
// file is in charge of importing all the other partials needed for the theme.
//

//
// Partials to be shared with all .scss files.
//

// @import 'compass/support';                     // Add Compass' IE and vendor prefix support variables.
// @import 'compass/utilities/general/clearfix';  // Better than Drupal's clearfix.
// @import 'compass/utilities/sprites';           // See http://compass-style.org/help/tutorials/spriting/
// @import 'compass/css3';                        // Use one CSS3 mixin instead of multiple vendor prefixes.


//
// Import custom partial - mixins and variables defined for your theme
//

// Compass currently not working with atom - so we switch to http://bourbon.io/
//@import "compass";

// We dont need compass or bourbon anymore as we use gulp + autoprefixer
// @import "../node_modules/bourbon/app/assets/stylesheets/bourbon";

@import 'configuration/variables';
@import 'configuration/bs_variables';
@import 'configuration/gridsystem';

@import 'libraries/_bootstrap/mixins';
@import 'mixins/breakpoints';
@import 'mixins/buttons';
@import 'mixins/gridsystem';
@import 'mixins/helper';
@import 'mixins/objects';
