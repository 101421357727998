
// -ODO: transform underscore variables to dash

// ***********************************************
// Settings
// ***********************************************

// Full width Page (full width regions, just the .inner containers get a max-width and will be centered)
$full-width-page: true;
// Touch Menu Opener Label anzeigen "Menu"
$show-touch-menu-label: false;
// Footer Menu Vertikal oder Horizontal darstellen
$show-footer-menu-vertical: false;
// Footer as Mini Panel
$footer-mini-panel: true;
// Dock Form Actions (Save, Delete, etc.)
$dock-form-actions: true;
// Navigation Bar has a search-block inside it? (Headroom.js)
$navbar-searchblock: false;
$headroom-searchblock: false;
// Mobile Bar visible all the time at defined device size - even when scrolling down. Set to false to get the headroom.js behavoir on all devices.
$headroom-effect: true; // the mobile bar will be hidden while scrolling down, visible when scrolling up again
$headroom-effect-breakpoint: 'xs'; // takes effect up this breakpoint
$headroom-docking-method: triggered; // choose "triggered" or "permanent". Triggered will show the bar after overscrolling an defined (headroom module settings) element
$headroom-docking-permanent-breakpoint: 'lg'; // switch from "triggered" to "permanent" down this breakpoint (usefull if you want to show the bar permenantly just on smaller devices)

// Tile Grid
$layout-tile-grid-active: false;
// EU Cookie Compliance Message
// -> See global.objects.scss - you have to manually comment this thing in/out till we´re able to use @import inside @if $eu-cookie-compliance-active: true;

// ***********************************************
// Typography
// These font stacks are an exact copy of the stacks found in AT Cores
// theme settings for web safe fonts.
// ***********************************************

$font-first: 'Open Sans', Verdana, Geneva, 'DejaVu Sans', Arial, Helvetica, sans-serif;
$font-second: Georgia, serif;
$font-code: Consolas, Monaco, 'Courier New', Courier, monospace, sans-serif;

$iconfont: 'iconfont';
$icon-size-xxs: 8px;
$icon-size-xs: 12px;
$icon-size-normal: 18px;
$icon-size-xl: 32px;
$icon-size-xxl: 56px;
$icon-size-xxxl: 74px;

$font-size: 100%;  // Basic font size (for Body)
$font-size-px: 16px;
$font-normal: $font-size-px; // 14 Pixel (for reset purpose)
$font-small: 12px;
$font-small-px: $font-small;
$font-medium-px: 26px;
$font-medium: $font-medium-px;
$font-large: 30px;
$font-large-px: $font-large;
$font-xlarge-px: 60px;
$font-weight-thin: 400; // we currently dont use a special thin variant
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$font-second-size-px: 16px;
$font-second-weight-normal: 700;

$line-height: 1.571;
$line-height-medium: 1.35;
$line-height-small: 1.5; // Line height for $font-small
$line-height-large: 1.3333333;
$line-height-xlarge: 1.15;

$paragraph-spacing: $line-height * .6;

// Headings
$headline-font: $font-first;
$headline-font-weight: $font-weight-normal;

$h1-font-size: 32px;
$h1-line-height: $line-height-small;
$h1-font-weight: $headline-font-weight;
$h1-font-family: $headline-font;

$h2-font-size: 22px;
$h2-line-height: $line-height-medium;
$h2-font-weight: $headline-font-weight;
$h2-font-family: $headline-font;

$h3-font-size: 18px;
$h3-line-height: $line-height-medium;
$h3-font-weight: $headline-font-weight;
$h3-font-family: $headline-font;

$h4-font-size: $font-size-px;
$h4-line-height: $line-height-medium;
$h4-font-weight: $font-weight-bold;
$h4-font-family: $font-first;

$h5-font-size: .83em;
$h5-line-height: $line-height-medium;
$h5-font-weight: $font-weight-bold;
$h5-font-family: $font-first;

$h6-font-size: .75em;
$h6-line-height: $line-height-medium;
$h6-font-weight: $font-weight-bold;
$h6-font-family: $font-first;

// ***********************************************
// Color Variables
// Colors used in the sample global.styles. You can use these to recolor many
// elements such as forms, tables, tabs etc.
// ***********************************************

// webks
$webks-grey: #454545;
$webks-grey-light: #e2e2e2;
$webks-blue: #2e90d0;
$drowl-green: #83b441;

// Colors
$white: #fff;
$black: #626366; // vorher 1b1b1b
$transparent: rgba(255, 255, 255, 0);

$highlight: $black;    // Primary Company CD color
$highlight-light: lighten($highlight, 3%);
$highlight-lighter: lighten($highlight, 35%);
$highlight-dark: darken($highlight, 3%);
$highlight-sec: $highlight;    // Secondary Company CD color
$highlight-sec-dark: darken($highlight-sec, 3%);
$highlight-sec-light: lighten($highlight-sec, 3%);
$highlight-cta: #f6d401;
$black-glass: rgba($black, .85);
$white-glass: rgba(255, 255, 255, .85);
$white-glass-light: rgba(255, 255, 255, .65);

$debug:         rgba(255, 255, 0, .5);

// Basic Greytone
$grey-base:              #d8d9da !default;
$grey-darker:            #3c3c3c !default;
$grey-dark:              #636466 !default;
$grey-alt:               #d7d7d7;
$grey:                   $grey-base !default;
$grey-light:             lighten($grey-base, 5%) !default;
$grey-lighter:           lighten($grey-base, 15%) !default;

// Success
$sucess: #5cb85c;
$sucess-light: lighten($sucess, 5%);
$sucess-dark: darken($sucess, 5%);

// Info
$info: #5bc0de;
$info-light: lighten($info, 5%);
$info-dark: darken($info, 5%);

// Warning
$warning: #f0ad4e;
$warning-light: lighten($warning, 5%);
$warning-dark: darken($warning, 5%);

// Danger
$danger: #d9534f;
$danger-light: lighten($danger, 5%);
$danger-dark: darken($danger, 5%);

// Background Colors
$body-background: $white;
$page-background: $white;
$highlight-bg-color: $highlight;
$full-width-bg-color: $white;
$area-hover-bg-color: lighten($grey, 30%);

// Text Colors
$text-color: $black;
$title-color: darken($text-color, 10%);
$highlight-text-color: #fff; // Text color on highlight-bg

// Touch / Tap Color
$touch-tap-color: rgba(0, 0, 0, .1);

// (Text) Selection Color
$selection-color: $highlight;
$selection-color-foreground: $highlight-text-color;

// Link Colors
$link-color: darken($text-color, 10%);
$link-color-hover: $highlight; // hover
$link-color-active: $link-color-hover; // active
$link-color-focus: $link-color-hover; // focus
$link-color-visited: $link-color; // visited
$highlight-link-color: $highlight; // Link color on highlight-bg
$link-text-decoration: none;
$link-text-decoration-hover: underline;

// Menus
$menu-flyout-background: $highlight;
$menu-flyout-color: $highlight-text-color;
$menu-flyout-min-width: 320px;
$menu-flyout-max-width: 100%;
$menu-flyout-padding-v: 5px;
$menu-flyout-padding-h: 0;
$menu-offcanvas-background: $highlight;
$menu-offcanvas-color: $highlight-text-color;

// Page Element Colors
$border-color: $grey;    // all borders set in global.styles - tables, fieldset, primary links
$cutline-color: $grey;

// Social Media Colors
$facebook-color: #3b5998;
$pinterest-color: #cb2027;
$gplus-color: #dd4b39;

// Shadows
$shadow-intensity: rgba(0, 0, 0, .4);
$shadow: 5px 7px 1px -2px $shadow-intensity;
$shadow-small: 0 5px 3px -2px $shadow-intensity;
$shadow-flyout:0 8px 7px -2px $shadow-intensity;

// Text Shadows
$text-shadow-intensity: rgba(0, 0, 0, .36);

// Overlays
$overlay-background-color: rgba(255, 255, 255, .45);

// Images
$image-background-color: $page-background;

// Forms
$placeholder-color: $text-color;

$tabledrag-icon-width: 41px;
$image-preview-width: 110px; // preview image on image fields

$form-description-color: $text-color;
$form-item-border: $grey;
$form-item-background: $white;
$form-item-border-hover: $grey-dark;
$form-item-border-active: $grey-dark;

$styled-checkboxes: true;
$styled-checkboxes-size: 24px;

// ***********************************************
// Animations
// ***********************************************
$transition-duration-default: 300ms;
$transition-duration-headroom: $transition-duration-default;

// ***********************************************
// Metrics
// ***********************************************
$border-radius: 8px;
$border-radius-small: 5px;
$border-radius-smaller: 3px; // just for eg. images inside a thumb wrapper which has radius small
$border-radius-large: 16px;

// ***********************************************
// Navigation
// ***********************************************
$main-menu-enable-expanded-arrows: false;


// ***********************************************
// Messages (Warnings etc.)
// ***********************************************
$message-border-radius:0;

$message-info-text: darken($info-dark, 20%);
$message-info-background: lighten($info-light, 20%);
$message-info-border: $info;

$message-success-text: darken($sucess-dark, 20%);
$message-success-background: lighten($sucess-light, 20%);
$message-success-border: $sucess;

$message-warning-text: darken($warning-dark, 20%);
$message-warning-background: lighten($warning-light, 20%);
$message-warning-border: $warning;

$message-error-text:  darken($danger-dark, 20%);
$message-error-background: lighten($danger-light, 20%);
$message-error-border: $danger;

// ***********************************************
// jQuery.UI
// ***********************************************
$jq-image-folder: "images/jquery.ui/";

$jq-border: $grey;
$jq-border-radius: 0;
$jq-background: $white;
$jq-color: $text-color;

$jq-highlight-background: $highlight;
$jq-highlight-border: $highlight;
$jq-highlight-color: $white;

$jq-hover-background: $highlight;
$jq-hover-border: $highlight;
$jq-hover-color: $white;

$jq-active-background: $highlight;
$jq-active-border: $highlight;
$jq-active-color: $white;

$jq-error-background: $danger-light;
$jq-error-border: $danger;
$jq-error-color: $danger;

$jq-header-background: $grey-light;

// ***********************************************
// Object Configuration
// ***********************************************

// Tooltips
$tooltip-color: $highlight;
$tooltip-text-color: $highlight-text-color;

// Media Object
$media-object-image-width: 160px;

// Mobile Menu Bar
$mobile-menu-bar-height: 76px;

// Inputs
$input-default-v-space: 15px;
$input-default-h-space: 20px;
$input-default-font-size: $font-size-px;
$input-default-line-height: $input-default-font-size + 2px;

$input-large-v-space: 24px;
$input-large-h-space: 24px;

// Buttons
$btn-default-style: 'default';
$btn-default-size: 'default';
$btn-radius: 0;

// -- Defaults
$btn-default-v-space: 15px;
$btn-default-h-space: $input-default-h-space;
$btn-default-line-height: $input-default-line-height;
$btn-default-font-size: $input-default-font-size;
$btn-default-border-color: $highlight;
$btn-default-bg-color: $highlight;
$btn-default-text-color: $highlight-text-color;

// -- Sizes
$btn-xs-v-space: ceil($input-default-v-space * .5);
$btn-xs-h-space: ceil($input-default-h-space * .5);
$btn-xs-font-size: $font-small-px;
$btn-xs-line-height: 1em;

$btn-xl-v-space: $input-default-v-space * 1.25;
$btn-xl-h-space: $input-default-h-space * 1.25;
$btn-xxl-v-space: $input-default-v-space * 1.15;
$btn-xxl-h-space: $input-default-h-space * 3.25;
$btn-xxxl-v-space: $input-default-v-space * 2.25;
$btn-xxxl-h-space: $input-default-h-space * 6.25;

// -- Ghost
$btn-ghost-border-color: $black;
$btn-ghost-bg-color: transparent;
$btn-ghost-text-color: $text-color;

// -- Ghost Dark (on dark backgrounds)
$btn-ghost-dark-border-color: darken($highlight-dark, 5%);
$btn-ghost-dark-bg-color: transparent;
$btn-ghost-dark-text-color: $highlight-text-color;
