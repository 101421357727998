
// Holds reusable objects for global use

// Functions
//recursive map-has-key() function
@function rmhk($m, $a){
  @if type-of($m) == map {
    @if map-has-key($m, $a) { @return true; };
    @each $key,$e in $m {
      @if rmhk($e, $a) { @return true; }
    }
  }
  @return false;
}

// Mixins
// *-mod means: It´s a modifier mixin, wich for eg. only change the color of a button.

// ***********************************************
// Icons
// ***********************************************

$icon-list: (
  arrow-bottom:"\edd8", // Arrow Bottom
  arrow-left:"\edda", // Arrow Left
  arrow-right:"\edd6", // Arrow Right
  arrow-thin-bottom:"\edc2", // Arrow thin Bottom
  arrow-thin-left:"\edc6", // Arrow thin Left
  arrow-thin-right:"\edbe", // Arrow thin Right
  arrow-thin-top:"\edba", // Arrow thin Top
  arrow-top:"\edd4", // Arrow Top
  cart:"\e9fe", // Shopping Cart
  check:"\ed72", // Check
  circle-arrow-bottom:"\ee2d", // Circled Arrow Bottom
  circle-arrow-left:"\ee2e", // Circled Arrow Left
  circle-arrow-right:"\ee2c", // Circled Arrow Right
  circle-arrow-top:"\ee2b", // Circled Arrow Top
  circle-check:"\ee76", // Circled Check
  circle-exclamation:"\ed52", // Circled Exclamation Mark (Ausrufezeichen)
  circle-info:"\ed64", // Circled info
  circle-minus:"\ed62", // Circled Minus
  circle-plus:"\ed61", // Circled Plus
  circle-remove:"\ed66", // Circled Remove (x)
  double-arrow-down:"\f103", // Double Arrow Down
  double-arrow-top:"\f102", // Double Arrow Top
  exclamation:"\f12c", // Exclamation Mark (Ausrufezeichen)
  facebook:"\eeef",
  form:"\e922", // Pen
  gplus:"\eeea",
  home:"\e901", // Home
  info:"\f12b", // info
  locked:"\eb50", // Lock (closed)
  mail:"\ea30", // Letter
  menu:"\ec71", // Menu
  minus:"\ed5e", // Minus
  phone:"\ea1d", // Phone
  plus:"\ed5d", // Plus
  print:"\ea68", // Printer
  process:"\eb60", // Cogs (Zahnraeder)
  remove:"\ed6e", // Remove (x)
  search: "\eb36", // Search
  twitter:"\eef5",
  unlocked:"\eb56", // Lock (open)
  user-add:"\eaf9", // Add User
  user-group:"\eaf8", // User Group
  user:"\eaf7", // User
  whatsapp: "\eef2",
  xing:"\ef33", // Xing
  youtube:"\eefc",
  zoom-in: "\eb38", // Zoom
  zoom-out: "\eb39", // Zoom-Out
  sort: "\ee51", // Sort / order

  // Customer specific custom icons
  car-search: "\e900",
  key: "\eb4b",
  pig: "\ea0f",
  play: "\ed7e",
  team: "\eb0c",
  workshop-date: "\e902"
);

// Get icon function - checks if the given icon exist in the list above.
$icon-list-keys: map-keys($icon-list);

@function get-icon($icon-key){
  $n: index($icon-list-keys, $icon-key);
  @if $n != null{
    @return map-get($icon-list, $icon-key);
  }@else{
    @error "Sorry, icon not found. >> " + $icon-key;
  }
}

@mixin ico($icon-key: null, $icon-color: null, $icon-size: null){
  // Default inline icon
  &::before{
    font-family:$iconfont;
    font-weight:normal !important;
    font-style:normal !important;
    margin-right:4px;
    @if($icon-key != null){
      content: get-icon($icon-key);
    }
    @if($icon-color != null){
      color:$icon-color;
    }
    @if($icon-size != null){
      font-size:$icon-size;
    }
  }
}
@mixin ico-replacement($icon-key: null, $icon-color: null, $icon-size: null, $icon-wrapper-width: null){
  // Replaces the elements text with an icon
  text-indent:-9000px;
  text-align:left;
  display:inline-block;
  float:left; // we changed this a serveral times.. inside buttons we NEED to use float or something. If this cause problems inside other elements - we need to do this specific for .btn. Otherwhise the button will get height as the item, which causes non equal height buttons.
  margin-right:4px;
  font-size:0; // fixing a bug on the android stack browser if theres text inside the icon element
  @if($icon-wrapper-width != null){
    width:$icon-wrapper-width;
  }@else{
    width:$icon-size-normal; // Fallback width - default icon size
  }
  &::before{
    text-indent:0;
    font-family:$iconfont;
    float:right;
    text-align:center;
    width:100%;
    @if($icon-key != null){
      content: get-icon($icon-key);
    }
    @if($icon-color != null){
      color:$icon-color;
    }
    @if($icon-size != null){
      font-size:$icon-size;
    }@else{
      font-size:$icon-size-normal;
    }
  }
  @include clearfix-after;
}

@mixin get-ico($icon-key: null){
  // Gets only the icon letter (for custom implementation)
  @if($icon-key != null){
    &::before{
      font-family:$iconfont;
      content: get-icon($icon-key);
    }
  }@else{
    @error "You have to define an icon."
  }
}

@mixin get-ico-only($icon-key: remove){
  // Gets only the icon letter (for custom implementation)
  content: get-icon($icon-key);
}

// =============================================================================
//  Navigation
// =============================================================================

// Tiny Menu
@mixin tiny-menu($menu-direction: null){
  li{
    @if($menu-direction == "horizontal"){
      float:none;
      display:inline-block;
      margin-right:$thin-grid;
      &:last-child{
        margin-right:0;
      }
    }
    a{
      padding:2px 0;
    }
  }
}

// =============================================================================
//  Box Styles
// =============================================================================

@mixin box-style($color-scheme: default){
  border-width:1px;
  border-style:solid;
  padding:$grid-vertical $grid;
  // Color
  @include box-style-mod($color-scheme);
  @include clearfix;
}

// Box Style Modifier
@mixin box-style-mod($color-scheme){
  // Adds only the new colors, not the entire box code
  @if($color-scheme == info){
    position:relative;
    font-size:$font-small;
    padding-left:60px;
    line-height:1.1em;
    border-color:darken($info, 8%);
    text-shadow:1px 1px 1px darken($info, 8%);
    background-color:$info;
    color: $white;
    cursor:help;
    @include get-ico('info');
    &::before{
      text-shadow:none;
      font-family:$iconfont;
      color:darken($info, 8%);
      background-color: $white;
      height:30px;
      width:30px;
      border-radius:50%;
      text-align:center;
      line-height:30px;
      vertical-align:middle;
      position:absolute;
      left:15px;
      top:13px;
      font-size:22px;
    }
  }@else{
    // Default
    border-color:$grey-light;
    border-radius:$border-radius;
    background-color: $white;
  }
}

// =============================================================================
//  Messages
// =============================================================================

@mixin message($color-scheme: default){
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: $message-border-radius;
  position:relative;
  &::before{
    font-family:$iconfont;
    position:absolute;
    left:15px;
    top:14px;
    font-size:25px;
    line-height:normal;
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
  @include message-mod($color-scheme);
}
@mixin message-mod($color-scheme){
  @if($color-scheme == info){
    color: $message-info-text;
    background-color: $message-info-background;
    border-color: $message-info-border;
    a{ color:$message-info-text; }
    &::before{
      @include get-ico-only('circle-info');
    }
  }@else if($color-scheme == success){
    color: $message-success-text;
    background-color: $message-success-background;
    border-color: $message-success-border;
    a{ color:$message-success-text; }
    &::before{
      @include get-ico-only('circle-check');
    }
  }@else if($color-scheme == warning){
    color: $message-warning-text;
    background-color: $message-warning-background;
    border-color: $message-warning-border;
    a{ color:$message-warning-text; }
    &::before{
      @include get-ico-only('circle-exclamation');
    }
  }@else if($color-scheme == error){
    color: $message-error-text;
    background-color: $message-error-background;
    border-color: $message-error-border;
    a{ color:$message-error-text; }
    &::before{
      @include get-ico-only('circle-remove');
    }
  }@else{
    color: $text-color;
    background-color: $white;
    border-color: $grey-light;
    a{
      color: $text-color;
    }
    &::before{
      @include get-ico-only('circle-info');
    }
  }
}


// ***********************************************
// Badges
// ***********************************************
@mixin badge($color-scheme: 'default', $icon: null){
  display: inline-block;
  font-size: $font-small;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius:$border-radius-small;
  padding:3px 5px;

  // Icons
  &::before{
    display:inline-block;
    margin-right:3px;
  }

  // Color
  @if($color-scheme != null){
    @include badge-mod($color-scheme);
  }
}

// Badge Modifier
@mixin badge-mod($color-scheme){
  @if($color-scheme == 'red'){
    background-color:$danger !important;
    color: $white !important;
  }@else if( $color-scheme == 'green'){
    background-color:$sucess !important;
    color: $white !important;
  }@else if( $color-scheme == 'grey'){
    background-color: $grey-light !important;
    color:$grey !important;
  }@else{
    background-color:$highlight !important;
    color: $highlight-text-color !important;
  }
}

// ***********************************************
// Misc
// ***********************************************

// Flyout
@mixin flyout($padding: $mobile-grid, $direction: right){
  background: $white;
  color:$text-color;
  padding:$padding;
  box-shadow:$shadow;
  min-width:235px;
  z-index:9999;

  position:absolute;
  top:100%;
  @if($direction == left){
    right:0;
  }@else{
    left:0;
  }
}

// Menu flyout
// TODO: What is the different from the regular flyout mixin above?
@mixin menu-flyout{
  position:absolute;
  z-index:99;

  background:$menu-flyout-background;
  color:$menu-flyout-color;
  padding:$menu-flyout-padding-v $menu-flyout-padding-h;
  width:100%;
  min-width:$menu-flyout-min-width;
  max-width:$menu-flyout-max-width;
  @if($menu-flyout-padding-v > 0){
    margin-top:$menu-flyout-padding-v * -1;
  }
  // Link & Border Color
  li{
    border-color:darken($menu-flyout-background, 5%);
  }
  a{
    color:$menu-flyout-color;
  }
}

// Overlay
@mixin overlay{
  display:none;
  position:absolute;
  top:0;
  left:0;
  z-index:9998;
  height:100%;
  width:100%; // overriden by jquery
  background:$overlay-background-color;
}

// Cutline
@mixin cutline($direction: bottom, $spacing-one: $grid-vertical, $spacing-two: $grid-vertical){
  @if($direction == top){
    border-top:1px solid $cutline-color;
    padding-top:$spacing-one;
    margin-top:$spacing-two;
  }@else if($direction == left){
    border-left:1px solid $cutline-color;
    padding-left:$spacing-one;
    margin-left:$spacing-two;
  }@else if($direction == right){
    border-right:1px solid $cutline-color;
    padding-right:$spacing-one;
    margin-right:$spacing-two;
  }@else{
    border-bottom:1px solid $cutline-color;
    padding-bottom:$spacing-one;
    margin-bottom:$spacing-two;
  }
}
